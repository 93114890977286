import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton
} from "@mui/material"

import Alert from '@mui/material/Alert';
import EditSharpIcon from '@mui/icons-material/EditSharp';

// context
import { updateAdditionalNameAsync, clearError, PeopleState } from "./peopleSlice"

import PaperComponent from '../../widgets/PaperComponent';

const EditAdditionalName = (props) => {

    const { error } = useSelector(PeopleState)
    const dispatch = useDispatch()

    const initialState = {
        open: false,
        firstName: props.dataFromParent.firstName,
        middleName: props.dataFromParent.middleName,
        lastName: props.dataFromParent.lastName,
        suffix: props.dataFromParent.suffix
    }

    const [dialog, setDialog] = useState(initialState)
    const { open, firstName, middleName,lastName,suffix } = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = (e) => {
        dispatch(clearError())
        dispatch(updateAdditionalNameAsync({id:props.dataFromParent.id, firstName, middleName,lastName,suffix }))
        setDialog(initialState)
    }

    const onChange = e => setDialog({...dialog, [e.target.name]: e.target.value})

    return (
        <Fragment>
            <IconButton color='inherit' onClick={handleClickOpen}>
                <Tooltip title='Edit Additional Name'>
                    <EditSharpIcon fontSize='small' style={{color: '#000032'}}/>
                </Tooltip>
            </IconButton>                   
    
            <Dialog open={open} id="editAdditionalNameModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}> 
                <DialogTitle id='form-dialog-title'>Update Additional Name</DialogTitle>
                <DialogContent>
                <Grid item xs={12} >&nbsp;</Grid>
                    <DialogContentText>
                        { error && <Alert severity={'error'}>{error}</Alert> }
                    </DialogContentText>

                    <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='firstName'
                                name='firstName'
                                label='First Name'
                                value={firstName}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid><Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='middleName'
                                name='middleName'
                                label='Middle Name'
                                value={middleName}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid><Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='lastName'
                                name='lastName'
                                label='Last Name'
                                value={lastName}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='suffix'
                                name='suffix'
                                label='Suffix'
                                value={suffix}
                                onChange={onChange}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default EditAdditionalName