import React, {Fragment, useState, useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux"

import validator from 'validator'
import moment from 'moment' 

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Select,
    InputLabel,
    FormControl,
    TextField,
    Tooltip,
    Button,
    IconButton
} from "@mui/material"
import PersonAddSharpIcon from '@mui/icons-material/PersonAddSharp';

// context
import {PeopleState, addNewPersonAsync, clearError, setErrorAsync} from './peopleSlice'
import {BatchState, loadRateOptionsAsync} from '../batches/batchSlice'
import {AuthState} from '../auth/authSlice'

import ShowAlert from '../../widgets/ShowAlert'
import useEnhancedStates from '../../utils/enhancedStates'

const AddPerson = (props) => {

const {user} = useSelector(AuthState)

const { loading, error } = useSelector(PeopleState)
const { rateOptions } = useSelector(BatchState)
const dispatch = useDispatch()
const dateFormats = ['YYYY/MM/DD', 'MM/DD/YYYY','YYYY-MM-DD','MM-DD-YYYY']

useEffect(() => {
    dispatch(loadRateOptionsAsync(user.tenantId))

}, [])

const displayRate = rateOptions.length <= 1 ? 'none' : '' 

const initialState = {
    open: false,
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    email: '',
    phone: '',
    street: '',
    region: '',
    postalCode: '',
    referral: '',
    gender: '',
    birthDate: '',
    city: '',
    addressUse: '',
    phoneType: '',
    emailType: '',
    rate: ''
}

const [dialog, setDialog] = useState(initialState)
let { open, email, firstName, lastName, middleName, suffix, phone, street, region, postalCode, gender, birthDate, city, addressUse, emailType, phoneType, rate } = dialog



useEffect(() => {
    if(rateOptions){
        const defaultRate = rateOptions.filter(a =>{ return a.isDefault === true} )
        const defaultRateValue = defaultRate[0]?.id
        setDialog({...dialog, rate: defaultRateValue})
        }
    
},[rateOptions])

    const handleClickOpen = () => { setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }

    const handleSubmit = (e) => {
        const addresses = [{use:addressUse, line1:street, city, state: region,postalCode}]
        const contactPoints = []
        if(phone !== ''){
            contactPoints.push({use:phoneType,system:'phone', value:phone})
        }

        if(email !== ''){
            contactPoints.push({use:emailType,system:'email', value:email})
        }

        const person = {firstName, middleName, suffix, lastName,  birthDate, gender,addresses, contactPoints, rate}
        if(firstName === ''){
            dispatch(setErrorAsync('First name is required!'))
            return
        }
        if(lastName === ''){
            dispatch(setErrorAsync('Last name is required!'))
            return
        }
        if(addressUse === ''){
            dispatch(setErrorAsync('Address use is required!'))
            return
        }
        if(!validator.isPostalCode(postalCode,'US')){
            dispatch(setErrorAsync('Postal Code is invalid!'))
            return
        }
        if(phone !== '' && phoneType === ''){
            dispatch(setErrorAsync('Phone type is required!'))
            return
        }
        if(phone !== '' && !validator.isMobilePhone(phone)){
            dispatch(setErrorAsync('Phone number is invalid!'))
            return
        }
        if(email !== ''){
            if(emailType === ''){
                dispatch(setErrorAsync('Email type is required!'))
                return
            }
            if(!validator.isEmail(email)){
                dispatch(setErrorAsync('Email address is invalid!'))
                return
            }
        }
        if(!moment(birthDate, dateFormats,true).isValid()){
            dispatch(setErrorAsync('Birth date is invalid!'))
            return
        }
        if(gender === ''){
            dispatch(setErrorAsync('Gender is required!'))
            return
        }
        dispatch(clearError())
        dispatch(addNewPersonAsync(person))
        setDialog(initialState)
    }
    
    const onChange = e => {
        setDialog({...dialog, [e.target.name]: e.target.value})
    }

    const stateOptions = useEnhancedStates()

const selectTypes = [{key:'home',label:'Home', value:'home'},{key: 'work',label:'Work', value:'work'},{key:'temp',label:'Temp', value:'temp'}]

if (!loading) return (
    <Fragment>
        <IconButton color='inherit' onClick={handleClickOpen}>
        <Tooltip title='Add New Patient'>
            <PersonAddSharpIcon fontSize='large' style={{color: '#000032'}}/>
        </Tooltip>
        </IconButton>                   
 
    <Dialog open={open} id="addModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} >
    <DialogTitle id='form-dialog-title'>Add New Patient</DialogTitle>
    <DialogContent>

        <DialogContentText>
            {
                error && <ShowAlert severity={'error'}>{error}</ShowAlert>
            }
        </DialogContentText>

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    type='text'
                    id='firstName'
                    name='firstName'
                    label='First Name'
                    value={firstName}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    required
                    size={'small'}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type='text'
                    id='middleName'
                    name='middleName'
                    label='Middle Name'
                    value={middleName}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    size={'small'}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type='text'
                    id='lastName'
                    name='lastName'
                    label='Last Name'
                    value={lastName}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    required
                    size={'small'}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type='text'
                    id='suffix'
                    name='suffix'
                    label='Suffix'
                    value={suffix}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    size={'small'}
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" style={{minWidth: 200}} required>
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">Address Use</InputLabel>
                <Select
                    native
                    required
                    size={'small'}
                    value={addressUse}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Address Use"
                    inputProps={{
                        name: 'addressUse',
                        id: 'addressUse',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {selectTypes.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>     
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='street'
                    name='street'
                    label='Street Address'
                    value={street}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='city'
                    name='city'
                    label='City'
                    value={city}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" >
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">State</InputLabel>
                <Select
                    native
                    size={'small'}
                    value={region}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="State"
                    inputProps={{
                        name: 'region',
                        id: 'region',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {stateOptions.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>
                    
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='postalCode'
                    name='postalCode'
                    label='Postal Code'
                    value={postalCode}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" style={{minWidth: 200}} >
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">Phone Type</InputLabel>
                <Select
                    native
                    size={'small'}
                    // required
                    value={phoneType}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Phone Type"
                    inputProps={{
                        name: 'phoneType',
                        id: 'phoneType',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {selectTypes.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>     
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type='text'
                    id='phone'
                    name='phone'
                    label='Phone'
                    value={phone}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    // required
                    size={'small'}
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" style={{minWidth: 200}}>
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">Email Type</InputLabel>
                <Select
                    native
                    value={emailType}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Email Type"
                    size={'small'}
                    inputProps={{
                        name: 'emailType',
                        id: 'emailType',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {selectTypes.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>     
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type='text'
                    id='email'
                    name='email'
                    label='Email'
                    value={email}
                    onChange={onChange}
                    variant='outlined'
                    size={'small'}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type='text'
                    id='birthDate'
                    name='birthDate'
                    label='Birth Date'
                    value={birthDate}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    size={'small'}
                    required
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" required>
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">Gender</InputLabel>
                <Select
                    native
                    size={'small'}
                    value={gender}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Gender"
                    inputProps={{
                        name: 'gender',
                        id: 'gender',
                    }}
                    >
                    <option aria-label="None" value=""/>
                    <option aria-label="Male" value="Male">Male</option>
                    <option aria-label="Female" value="Female">Female</option>
                    <option aria-label="Other" value="Other">Other</option>
                    <option aria-label="Unknown" value="Unknown">Unknown</option>
                    
                </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12}>
                        <FormControl variant="outlined" style={{minWidth: 200, display: displayRate}} required>
                            <InputLabel htmlFor="outlined-age-native-simple">Rate</InputLabel>
                            <Select
                                native
                                required
                                value={rate}
                                onChange={onChange}
                                label="Rate"
                                size={'small'}
                                inputProps={{
                                    name: 'rate',
                                    id: 'rate',
                                }}
                                >
                                {rateOptions.map(item => {
                                    return (<option key={item.id} value={item.id}>{item.display}</option>);
                                    })}
                            </Select>
                        </FormControl>     
                    </Grid>
        </Grid>

    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
            Cancel
        </Button>
        <Button onClick={handleSubmit} color={'primary'}>
            Submit
        </Button>
    </DialogActions>
</Dialog>
</Fragment>
)
else 
        return (<div></div>)
        }
export default AddPerson