import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Tooltip,
    Button,
    Chip
} from "@mui/material"
import Alert from '@mui/material/Alert';

// context
import { PeopleState, clearError, deletePatientAsync} from './peopleSlice'

import PaperComponent from '../../widgets/PaperComponent';

const DeletePerson = ({person}) => {

    const { error } = useSelector(PeopleState)
    const dispatch = useDispatch()

    const {id, firstName, lastName, isActive} = person

    const initialState = {
        open: false,
    }

    const [dialog, setDialog] = useState(initialState)
    const { open } = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = () => {
        dispatch(deletePatientAsync(id))
        console.log('clicked')
        setDialog(initialState)
    }

    if(isActive)
        return (
            <Fragment>
                <Box display='flex' justifyContent='flex-mid' >
                    <Tooltip title='Remove Patient' >
                        <Chip
                            variant="filled"
                            sx={{ marginTop: 1, marginLeft: 1 }}
                            color="primary"
                            size='small'
                            label="Remove"
                            onClick={() => {handleClickOpen()}}>
                        </Chip>
                    </Tooltip>
                </Box>

                <Dialog open={open} id="setArchiveStatusModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <DialogTitle id='form-dialog-title'>Remove Patient</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { error && <Alert severity={'error'}>{error}</Alert> }
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <DialogContentText>You are about to remove {firstName} {lastName} from the system and network </DialogContentText>
                        <DialogContentText>Do you wish to continue?</DialogContentText>
                        </Grid>   
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            </Fragment>
        )

}

export default DeletePerson