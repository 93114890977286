import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const slice = createSlice({
    name: 'people',
    initialState: {
        loading: true,
        people: [],
        peopleBasic: [],
        person: null,
        error: null,
        pageSize: 25,
        // page: 0,
        // peopleCount: null,
        // maxPage: null,
        previouslyLoaded: null,
        sort: [{field:"lastName", sort:"asc"}],
        cwLinks:[],
        success: null,
        viewFile: null,
        FileId: null,
        merged: null,
        noFacility: null,
        reciprocalPatients: null
    },
    reducers: {
        loadPeople: (state, action) => {
            const { people, page, PAGE_SIZE, totalPeople, maxPage } = action.payload

            state.people = people
            // if (page) state.page = action.payload.page - 1
            // if (PAGE_SIZE) state.pageSize = action.payload.PAGE_SIZE
            // if (totalPeople) state.peopleCount = action.payload.totalPeople
            // if (maxPage) state.maxPage = action.payload.maxPage
            state.loading= false
            state.previouslyLoaded = true
        },
        loadPeopleBasic: (state, action) => {
            state.peopleBasic = action.payload
            state.loading = false
        },
        loadReciprocal:(state, action) => {
            state.reciprocalPatients = action.payload
            state.loading= false
        },
        loadPerson: (state, action) => {
            state.person = action.payload
            state.loading= false
        },
        setCWLinks: (state,action) => {
            state.cwLinks = action.payload
        },
        setLoading: state => { state.loading = true },
        setError: (state, action) => { state.error = action.payload
                                        state.loading = false},
        setSuccess: (state, action) => { state.success = action.payload},
        setMerged: (state, action) => { state.merged = true},
        setNoFacility: (state, action) => { state.noFacility =  action.payload},
        clearError: state => { state.error = null },
        setPage: (state, action) => {
            state.page = action.payload
            state.loading= false
        },
        setPageSize: (state, action) => {
            state.pageSize = action.payload
            state.loading= false
        },
        setSort: (state, action) => {
            state.sort = action.payload
            state.loading= false
        },
        setViewFile: (state, action) => {
            state.viewFile = action.payload.file
            state.FileId = action.payload.id
            state.loading= false
        }
    }
})


export const { loadPeople, loadPeopleBasic, loadReciprocal, loadPerson, setViewFile,setNoFacility, setLoading,setMerged, setError, setSuccess,clearError, setPageSize, setPage, setselectedIds, setDisableContactEdit, loadFiles, setSort, setCWLinks } = slice.actions


export const getPeopleAsync = () => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/patient`)//,{params: {
        //     page: page,
        //     pageSize: pageSize,
        //     filter: filter,
        //     sort: sort
        //   }})
        dispatch(loadPeople(res.data))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const getPeopleBasicAsync = () => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/patient/$basic`, {})
        dispatch(loadPeopleBasic(res.data ))
        // console.log(res.data)
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const getReciprocalPatientsAsync = () => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/patient/$reciprocal`, {})
        dispatch(loadReciprocal(res.data ))
        // console.log(res.data)
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const deleteProgressNoteAsync = (docId) => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/progressNote/${docId}`)
        window.location.reload()
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.message))
    }
}

export const mergePatientsAsync = (keep,destroy) => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/patient/$merge`, {keep,destroy})
        dispatch(getPeopleAsync())
        dispatch(setMerged())
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const deletePatientAsync = (patientId) => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/patient/${patientId}`)
        window.location = `/people`
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const unenrollPatientAsync = (patientId) => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/patient/${patientId}/$unenroll`)
        dispatch(getPersonAsync(patientId))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const setPageSizeAsync = (newPageSize) => async dispatch => {
    dispatch(setLoading())
    try {
        dispatch(setPageSize(newPageSize))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const setPageAsync = (newPage) => async dispatch => {
    dispatch(setLoading())
    try {
        dispatch(setPage(newPage))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const addNewPersonAsync = (person) => async dispatch => {
    dispatch(setLoading())
    const {firstName, middleName, suffix, lastName,  birthDate, gender,addresses, contactPoints, rate} = person
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/patient`,{firstName, middleName, suffix, lastName,  birthDate, gender,addresses, contactPoints, rate})
        window.location = `/people/${res.data.id}`
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const addNewAddressAsync = (person,addresses,submitType) => async dispatch => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/address`,{patientId: person.id,addresses})
        if(submitType === 'submit') dispatch(getPersonAsync(person.id))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const searchEndatoAddressAsync = (person,endatoSearchPayload) => async dispatch => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/endato`,{patientId: person.id,endatoSearchPayload})
        dispatch(getPersonAsync(person.id))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const updateAddressAsync = (addresses) => async dispatch => {
    dispatch(setLoading())

    const {type, use, line1, line2, city, state, postalCode, id} = addresses

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/v1/address/${id}`,{type, use, line1, line2, city, state, postalCode})
        window.location.reload()
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const addNewAdditionalNameAsync = (person,additionalName,submitType) => async dispatch => {
    const {firstName,lastName, middleName, suffix} = additionalName

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/additionalName`,{patientId: person.id,additionalName:[{firstName,lastName, middleName, suffix}]})
        if(submitType === 'submit') dispatch(getPersonAsync(person.id))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const updateAdditionalNameAsync = name => async dispatch => {
    dispatch(setLoading())

    const { id, firstName, middleName,lastName,suffix } = name

    try {
        if (!name) return
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/v1/additionalName/${id}`,{id,firstName, middleName,lastName,suffix })
        window.location.reload()
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const setErrorAsync = message => async dispatch => {
    dispatch(setError(message))
}

export const getPersonAsync = id => async dispatch => {
    dispatch(setLoading())
    try {
        if (!id) return
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/patient/${id}`)
        dispatch(loadPerson(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const updatePersonAsync = person => async dispatch => {
    dispatch(setLoading())

    const { firstName, lastName, middleName, gender, birthDate, id, suffix } = person

    try {
        if (!person) return
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/v1/patient/${ id}`,{firstName, lastName, middleName, gender, birthDate, id, suffix })
        window.location.reload()
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const addNewContactAsync = (person,contact,submitType) => async dispatch => {
    const {use,value, system} = contact

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/ContactPoint`,{patientId: person.id,contactPoints:[{use,value,system}]})
        if(submitType === 'submit') dispatch(getPersonAsync(person.id))
    } catch (err) {
        console.log(err)
        dispatch(setError(err.response.data.error))
    }
}

export const requestRateUpdateAsync = request => async dispatch => {
    dispatch(setLoading())

    const { id, rate,reason } = request

    try {
        if (!request) return
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/v1/PatientQuery/${id}`,{id,rate, reason })
        window.location.reload()
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const updateContactPointAsync = contact => async dispatch => {
    dispatch(setLoading())

    const { id, system, use, value } = contact

    try {
        if (!contact) return
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/v1/ContactPoint/${id}`,{id,system, use, value })
        window.location.reload()
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const searchCWPatient = id => async dispatch => {
    try {
        if (!id) return
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/patient/${id}/$searchEnrollment`)
        if(res.status === 201){
            dispatch(getPersonAsync(id))
        }
        else
            dispatch(setCWLinks(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const linkCWPatient = (id,link) => async dispatch => {
    dispatch(setLoading())
    try {
        if (!id) return
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/patient/${id}/$enroll/${link}`)
        dispatch(getPersonAsync(id))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const deleteAdditionalNameAsync = (id) => async dispatch => {
    dispatch(setLoading())
    try {
        if (!id) return
        const res = await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/additionalName/${id}`)
        window.location.reload()
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}


export const deleteContactPointAsync = (id) => async dispatch => {
    dispatch(setLoading())
    try {
        if (!id) return
        const res = await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/contactPoint/${id}`)
        window.location.reload()
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const deleteAddressAsync = (id) => async dispatch => {
    dispatch(setLoading())
    try {
        if (!id) return
        const res = await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/address/${id}`)
        window.location.reload()
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const PeopleState = state => state.people

export default slice.reducer