import React, {Fragment, useState} from 'react'
import { useDispatch } from "react-redux"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select
} from "@mui/material"
import EditSharpIcon from '@mui/icons-material/EditSharp';

// context
import { updateAddressAsync, clearError } from "./peopleSlice"

import PaperComponent from '../../widgets/PaperComponent';
import useEnhancedStates from '../../utils/enhancedStates';

const EditAddress = (props) => {

    const dispatch = useDispatch()

    const initialState = {
        open: false,
        type: props.dataFromParent.type,
        use: props.dataFromParent.use,
        line1: props.dataFromParent.line1,
        line2: props.dataFromParent.line2,
        city: props.dataFromParent.city,
        state: props.dataFromParent.state?.toUpperCase(),
        postalCode: props.dataFromParent.postalCode,

    }

    const [dialog, setDialog] = useState(initialState)
    const { open, type, use, line1, line2, city, state, postalCode } = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = (e) => {
        dispatch(clearError())
        dispatch(updateAddressAsync({id:props.dataFromParent.id,type, use, line1, line2, city, state, postalCode}))
        setDialog(initialState)
    }

    const onChange = e => setDialog({...dialog, [e.target.name]: e.target.value})

    const stateOptions = useEnhancedStates()

    const selectUses = [{key:'billing',label:'Billing', value:'billing'},{key:'home',label:'Home', value:'home'},{key:'old',label:'Old', value:'old'},{key:'temp',label:'Temp', value:'temp'},{key: 'work',label:'Work', value:'work'}]
    const selectTypes = [{key:'physical',label:'Physical', value:'physical'},{key: 'postal',label:'Postal', value:'postal'},{key:'both',label:'Both', value:'both'}]

    return (
        <Fragment>
            <IconButton color='inherit' onClick={handleClickOpen}>
                <Tooltip title='Edit Address'>
                    <EditSharpIcon fontSize='small' style={{color: '#939393'}}/>
                </Tooltip>
            </IconButton>                   
    
            <Dialog open={open} id="editAddressModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <DialogTitle id='form-dialog-title'>Update Address</DialogTitle>
                <Grid item xs={12} >&nbsp;</Grid>
                <DialogContent>
                <Grid container spacing={2}>
            <Grid item xs={12}>
            <FormControl variant="outlined" style={{minWidth: 200}} required>
                <InputLabel htmlFor="outlined-age-native-simple">Address Use</InputLabel>
                <Select
                    native
                    required
                    value={use}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Address Use"
                    inputProps={{
                        name: 'use',
                        id: 'use',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {selectUses.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>     
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" style={{minWidth: 200}} required>
                <InputLabel htmlFor="outlined-age-native-simple">Address Type</InputLabel>
                <Select
                    native
                    required
                    value={type}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Address Type"
                    inputProps={{
                        name: 'type',
                        id: 'type',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {selectTypes.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>     
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='line1'
                    name='line1'
                    label='Street Address'
                    value={line1}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='line2'
                    name='line2'
                    label='Address Line 2'
                    value={line2}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='city'
                    name='city'
                    label='City'
                    value={city}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined" >
                <InputLabel htmlFor="outlined-age-native-simple">State</InputLabel>
                <Select
                    native
                    value={state}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="State"
                    inputProps={{
                        name: 'state',
                        id: 'state',
                    }}
                    >
                    <option aria-label="None" value="" />
                    {stateOptions.map(item => {
                        return (<option key={item.key} value={item.value}>{item.label}</option>);
                        })}
                </Select>
            </FormControl>
                    
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='postalCode'
                    name='postalCode'
                    label='Postal Code'
                    value={postalCode}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    required
                />
            </Grid>

        </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default EditAddress