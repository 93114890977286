import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton
} from "@mui/material"
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';

import Separator from '../../widgets/Separator'
import ShowAlert from '../../widgets/ShowAlert'
import PaperComponent from '../../widgets/PaperComponent';

// context
import {PeopleState, addNewAdditionalNameAsync, clearError} from './peopleSlice'

const AddAdditionalName = (props) => {

const { loading, error, person } = useSelector(PeopleState)
const dispatch = useDispatch()

const initialState = {
    open: false,
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: ''

}

const [dialog, setDialog] = useState(initialState)
let { open,  firstName, lastName,middleName, suffix} = dialog

    const handleClickOpen = () => { setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }

    const handleSubmit = (e) => {
        dispatch(clearError())
        dispatch(addNewAdditionalNameAsync(person,{ firstName, lastName,middleName, suffix},'submit'))
        setDialog(initialState)
    }

    const handleSubmitandContinue = (e) => {

        dispatch(clearError())
        dispatch(addNewAdditionalNameAsync(person,{ firstName, lastName,middleName, suffix}, 'next'))
        setDialog({ ...dialog,firstName: '', middleName: '', lastName: '', suffix: ''})
    }
    
    const onChange = e => setDialog({...dialog, [e.target.name]: e.target.value})

if (!loading) return (
    <Fragment>
        <IconButton color='inherit' onClick={handleClickOpen}>
        <Tooltip title='Add Additional Name'>
            <AddCircleOutlineSharpIcon fontSize='medium' style={{color: '#000032'}}/>
        </Tooltip>
        </IconButton>                   
 
    <Dialog open={open} id="addNameModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
    <DialogTitle id='form-dialog-title'>Add Additional Name</DialogTitle>
    <DialogContent>

        <DialogContentText>
            {
                error && <ShowAlert severity={'error'}>{error}</ShowAlert>
            }
        </DialogContentText>

        <Separator />

        <Grid container spacing={2}>
        <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='firstName'
                    name='firstName'
                    label='First Name'
                    value={firstName}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='middleName'
                    name='middleName'
                    label='Middle Name'
                    value={middleName}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='lastName'
                    name='lastName'
                    label='Last Name'
                    value={lastName}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    margin='dense'
                    type='text'
                    id='suffix'
                    name='suffix'
                    label='Suffix'
                    value={suffix}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>

        </Grid>

    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
            Cancel
        </Button>
        <Button onClick={handleSubmitandContinue} color={'primary'}>
            Save & Continue
        </Button>
        <Button onClick={handleSubmit} color={'primary'}>
            Save & Close
        </Button>
    </DialogActions>
</Dialog>
</Fragment>
)
else 
        return (<div></div>)
        }
export default AddAdditionalName