import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react'

import { Provider } from 'react-redux'
import createStore from './configureStore'

const store = createStore()

const root = ReactDOM.createRoot(document.getElementById('app'));
console.log(root)
root.render(
    <React.StrictMode>
        <Auth0Provider domain={process.env.REACT_APP_DOMAIN}
                       audience={process.env.REACT_APP_AUD}
                       clientId={process.env.REACT_APP_CLIENT_ID}
                       redirectUri={window.location.origin}
                       scope={process.env.REACT_APP_SCOPE}
                       >
            <Provider store={store}>
                <App />
            </Provider>
        </Auth0Provider>
    </React.StrictMode>
);


