import Paper from '@mui/material/Paper';
import React from "react";
import Draggable from "react-draggable";

function PaperComponent(props) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
}

export default PaperComponent