import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"
import axios from 'axios'

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Button
} from "@mui/material"
import MyLocationIcon from '@mui/icons-material/MyLocation';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// context
import { PatientQueryState, clearError} from './patientQuerySlice'
import { setNoFacility} from '../people/peopleSlice'

import MuiAlert from '@mui/material/Alert';

import PaperComponent from '../../widgets/PaperComponent';

const FacilityComposite = ({person}) => {

    const { error } = useSelector(PatientQueryState)
    const dispatch = useDispatch()

    const {id, firstName, lastName} = person

    const initialState = {
        open: false,
        openLoader: false,
        facility: '',
        disableButton: true
    }

    const [dialog, setDialog] = useState(initialState)
    const { open, facility, disableButton, openLoader } = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = async () => {
        setDialog({...dialog, openLoader: true})
        const res = await axios.get(
            `${process.env.REACT_APP_API_BASE}/v1/Document/${id}/$filter?facility=${facility}`, {
            })

        const { signedUrl, fileName, message } = res.data

        if(message){
            dispatch(setNoFacility(message))
        }
        else  {
            const aTag = document.createElement('a')
            aTag.href = signedUrl
            aTag.setAttribute('download', fileName)
            document.body.appendChild(aTag)
            aTag.click()
            aTag.remove()
        }
 
        setDialog(initialState)
    }
    const onChange = e => {
        if(e.target.value.length > 0) setDialog({...dialog,[e.target.name]: e.target.value, disableButton: false})
        else setDialog({...dialog,[e.target.name]: e.target.value, disableButton: true})
    }

        return (
            <Fragment>
                <Box display='flex' justifyContent='flex-mid'  style={{marginLeft: 2}}>
                    <Button
                        variant="contained"
                        startIcon={<MyLocationIcon/>}
                        size={'small'}
                        onClick={() => {handleClickOpen()}}>Create Facility Composite</Button>
                </Box>

                <Dialog open={open} id="facilityCompositeModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openLoader}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                <DialogTitle id='form-dialog-title'>Create Facility Composite</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { error && <MuiAlert severity={'error'}>{error}</MuiAlert> }
                    </DialogContentText>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <DialogContentText>If you would like to create a facility specific composite for {firstName} {lastName} </DialogContentText>
                        <DialogContentText>Please enter the facility name below.</DialogContentText>
                        </Grid> 
                        <Grid item xs={12}>
                            <TextField
                                margin='dense'
                                type='text'
                                id='facility'
                                name='facility'
                                label='Facility'
                                value={facility}
                                onChange={onChange}
                                variant='outlined'
                            />
                        </Grid>   
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'} disabled={disableButton}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            </Fragment>
        )

}

export default FacilityComposite